.rt.rt-custom {
  padding: 6px 12px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
}

@media not all and (hover: hover) and (pointer: fine) {
  .rt.rt-custom {
    display: none;
  }
}

.dark .rt.rt-custom {
  background-color: #fff;
  color: #222;
}
