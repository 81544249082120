/* New auction bid history item enter transition */

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0) scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  75% {
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.auction-bid-item-enter .auction-bid {
  opacity: 0;
}

.auction-bid-item-enter-active .auction-bid {
  opacity: 1;
  animation: bounceInUp 1s;
}

@keyframes rubberBandFadeIn {
  from {
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }

  30% {
    opacity: 1;
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.auction-bid-item-enter .auction-high-bid-star {
  opacity: 0;
}

.auction-bid-item-enter-active .auction-high-bid-star {
  animation: rubberBandFadeIn 1.2s;
  animation-delay: 800ms;
}

.auction-bid-item-enter .auction-bid-line {
  opacity: 0;
}

.auction-bid-item-enter-done .auction-bid-line {
  opacity: 1;
  transition: opacity 200ms;
}
