:root {
  --vh: 1vh;
}

html.is-chat.is-safari,
html.is-chat.is-safari body {
  height: calc(var(--vh, 1vh) * 100);
}

html.is-chat,
html.is-chat body {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
