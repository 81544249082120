.accounts-badge-enter {
  opacity: 0;
}

.accounts-badge-enter-active {
  opacity: 1;
  animation: bounceIn 500ms;
}

.accounts-badge-exit {
  opacity: 1;
}

.accounts-badge-exit-active {
  opacity: 0;
  animation: bounceOut 500ms;
}
