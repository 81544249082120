@tailwind base;
@tailwind components;
@tailwind utilities;

.dark ::selection {
  background: rgba(56, 189, 248, 0.3);
}

@layer utilities {
  .text-shadow {
    text-shadow: 1px 1px 2px black;
  }

  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .highlight {
    box-shadow: inset 0 1px 0 0 hsl(0deg 0% 100% / 5%);
  }

  .dark-adjust {
    filter: brightness(0.9) contrast(1.1);
  }
}

@font-face {
  font-family: 'SF Mono';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/SFMono-Light.woff2') format('woff2'),
    url('/fonts/SFMono-Light.woff') format('woff'),
    url('/fonts/SFMono-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Mono';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/SFMono-Regular.woff2') format('woff2'),
    url('/fonts/SFMono-Regular.woff') format('woff'),
    url('/fonts/SFMono-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Mono';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/SFMono-Medium.woff2') format('woff2'),
    url('/fonts/SFMono-Medium.woff') format('woff'),
    url('/fonts/SFMono-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Algo';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Algo.woff2') format('woff2'), url('/fonts/Algo.woff') format('woff'),
    url('/fonts/Algo.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter avatar';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/Inter-SemiBold.woff2?v=3.19') format('woff2'),
    url('/fonts/Inter-SemiBold.woff') format('woff'), url('/fonts/Inter-SemiBold.ttf') format('ttf');
}

@font-face {
  font-family: 'SF Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/SF-UI-Display-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'SF Display';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/SF-UI-Display-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'SF Display';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/SF-UI-Display-Semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'SF Display';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/SF-UI-Display-Bold.woff2') format('woff2');
}

/* Hide email field in Sentry feedback form */
.sentry-error-embed .form-field:nth-child(2) {
  display: none;
}

/* Fix Pera modal appearing behind elements with z-index */
#pera-wallet-connect-modal-wrapper {
  position: fixed;
  z-index: 9999;
}

.walletconnect-modal__single_wallet {
  display: none !important;
}

.pera-wallet-wallet-redirect-modal {
  padding-top: 20px !important;
  display: block !important;
}

.pera-wallet-redirect-modal__launch-pera-wallet-button {
  margin-top: 20px !important;
}

/* Fix Defly modal appearing behind elements with z-index */
.defly-wallet-connect-modal {
  position: fixed;
  z-index: 9999 !important;
}

.dark .__floater__arrow span polygon {
  fill: #111827 !important;
}

.dark #purchased-tooltip + .__floater__arrow span polygon {
  fill: #1f2937 !important;
}
